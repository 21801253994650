/* eslint-disable no-restricted-syntax */
import { useState, createContext, useContext } from 'react';
// import { useToast } from './Toast';

const ModalProspectContext = createContext();

export function ModalProspectProvider({ children }) {
  const [showModalAviso, setShowModalAviso] = useState(false);
  const [showModalAvisoIndeferir, setShowModalAvisoIndeferir] = useState(false);
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [showModalDeletar, setShowModalDeletar] = useState(false);
  const [userData, setUserData] = useState();

  const openModalAviso = () => {
    setShowModalAviso((prev) => !prev);
  };

  const handleCloseModalAviso = () => {
    setShowModalAviso(false);
  };

  const openModalAvisoIndeferir = () => {
    setShowModalAvisoIndeferir((prev) => !prev);
  };

  const handleCloseModalAvisoIndeferir = () => {
    setShowModalAvisoIndeferir(false);
  };

  const openModalCadastro = () => {
    setShowModalCadastro(true);
  };

  const handleCloseModalCadastro = () => {
    setShowModalCadastro(false);
  };

  const openModalDeletar = () => {
    setShowModalDeletar((prev) => !prev);
  };

  const handleCloseModalDeletar = () => {
    setShowModalDeletar(false);
  };

  return (
    <ModalProspectContext.Provider
      value={{
        showModalAviso,
        setShowModalAviso,
        openModalAviso,
        handleCloseModalAviso,
        showModalAvisoIndeferir,
        setShowModalAvisoIndeferir,
        openModalAvisoIndeferir,
        handleCloseModalAvisoIndeferir,
        showModalCadastro,
        setShowModalCadastro,
        openModalCadastro,
        handleCloseModalCadastro,
        showModalDeletar,
        setShowModalDeletar,
        openModalDeletar,
        handleCloseModalDeletar,
        userData,
        setUserData,
      }}
    >
      {children}
    </ModalProspectContext.Provider>
  );
}

export function useModalProspect() {
  const context = useContext(ModalProspectContext);
  if (!context) {
    throw new Error('useModalProspect must be used within a ModalProvider');
  }
  return context;
}
