import { useContext, useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { subYears } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useFormik } from 'formik';
import { updateUser } from 'services/usuariosServices';
import formataCpf from 'util/formatarCPF';
import { formatarSalario } from 'util/formatarSalario';
import * as yup from 'yup';

import { Button } from 'components/Button';

import { ModalPatrocinadoraContext } from 'context/ModalPatrocinadora';
import { useProspect } from 'context/Prospects';
import { useToast } from 'context/Toast';

export function ModalPatrocinadora() {
  const { isOpen, close, prospect } = useContext(ModalPatrocinadoraContext);
  const { getAllUsuarios } = useProspect();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      cpf: '',
      matricula: '',
      dataAdmissao: null,
      dataNascimento: null,
      salario: '',
      sexo: '',
    },
    validationSchema: yup.object({
      nome: yup
        .string()
        .required('Nome é obrigatório')
        .matches(/^([a-zA-Z\u00C0-\u017F´]+\s)+[a-zA-Z\u00C0-\u017F´]+$/, {
          message: 'Nome inválido',
        }),
      cpf: yup
        .string()
        .required('CPF é obrigatório')
        .transform((value) => value.replace(/\D/g, ''))
        .test({
          test: (value) => /^\d{11}$/.test(value),
          message: 'CPF inválido',
        }),
      matricula: yup
        .string()
        .required('Matrícula é obrigatório')
        .test({
          test: (value) => Number(value) > 0,
          message: 'Matrícula inválida',
        }),
      dataAdmissao: yup
        .date()
        .nullable()
        .required('Data de admissão é obrigatório')
        .max(
          new Date(),
          'Data de adimissão não pode ser maior que a data atual'
        )
        .test({
          test: (value, context) => {
            const { dataNascimento } = context.parent;
            const diff =
              new Date(value).getFullYear() -
              new Date(dataNascimento).getFullYear();
            return diff >= 18;
          },
          message: 'Deve ser maior de idade',
        })
        .typeError('Data de admissão inválida'),
      dataNascimento: yup
        .date()
        .nullable()
        .required('Data de nascimento é obrigatório')
        .typeError('Data de nascimento inválida')
        .test({
          test: (value) => value <= subYears(new Date(), 18),
          message: 'Deve ser maior de idade',
        })
        .test({
          test: (value) => value >= new Date(1900, 0, 1),
          message: 'Data de nascimento inválida',
        }),
      salario: yup
        .string()
        .required('Salário é obrigatório')
        .test({
          test: (value) => value != 0,
          message: 'Salário inválido',
        }),
      sexo: yup.string().required('Sexo é obrigatório'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const loading = toast.loading('Salvando dados');
      try {
        await updateUser(prospect.cpf, values);
        toast.success('Dados salvos');
        getAllUsuarios();
        close();
      } catch (e) {
        toast.error(e.message);
      } finally {
        toast.dismiss(loading);
        setIsLoading(false);
      }
    },
  });

  const handleAmountChange = (event) => {
    const rawValue = event.target.value.replace(/[^0-9]/g, '');
    const numericValue = parseFloat(rawValue) / 100;
    formik.setFieldValue('salario', numericValue);
  };

  useEffect(() => {
    if (prospect) {
      formik.setValues({
        cpf: formataCpf(prospect.cpf),
        nome: prospect.nome,
        matricula: prospect.matriculaPatrocinadora,
        dataAdmissao: new Date(prospect.dataAdmissao),
        dataNascimento: new Date(prospect.dataNascimento),
        salario: prospect.salario,
        sexo: prospect.sexo,
      });
    }
  }, [prospect]);

  return (
    <Dialog fullWidth open={isOpen} onClose={close}>
      <DialogTitle>Editar dados</DialogTitle>
      <DialogContent dividers>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nome"
                name="nome"
                type="text"
                value={formik.values.nome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
                inputProps={{
                  maxLength: 60,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="CPF"
                name="cpf"
                type="text"
                inputProps={{ maxLength: 14 }}
                value={formik.values.cpf}
                onChange={(e) => {
                  formik.setFieldValue('cpf', formataCpf(e.target.value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                helperText={formik.touched.cpf && formik.errors.cpf}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Matrícula"
                name="matricula"
                type="text"
                value={formik.values.matricula}
                onChange={(e) => {
                  formik.setFieldValue(
                    'matricula',
                    e.target.value.replace(/\D/g, '').slice(-9).padStart(9, '0')
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.matricula && Boolean(formik.errors.matricula)
                }
                helperText={formik.touched.matricula && formik.errors.matricula}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}
              >
                <DatePicker
                  label="Data de admissao"
                  value={formik.values.dataAdmissao}
                  onChange={(value) => {
                    formik.setFieldValue('dataAdmissao', value);
                  }}
                  slotProps={{
                    textField: {
                      name: 'dataAdmissao',
                      onBlur: formik.handleBlur,
                      error:
                        formik.touched.dataAdmissao &&
                        Boolean(formik.errors.dataAdmissao),
                      helperText:
                        formik.touched.dataAdmissao &&
                        formik.errors.dataAdmissao,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}
              >
                <DatePicker
                  label="Data de nascimento"
                  value={formik.values.dataNascimento}
                  onChange={(value) => {
                    formik.setFieldValue('dataNascimento', value);
                  }}
                  slotProps={{
                    textField: {
                      name: 'dataNascimento',
                      onBlur: formik.handleBlur,
                      error:
                        formik.touched.dataNascimento &&
                        Boolean(formik.errors.dataNascimento),
                      helperText:
                        formik.touched.dataNascimento &&
                        formik.errors.dataNascimento,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Salário"
                name="salario"
                value={formatarSalario(formik.values.salario)}
                onChange={handleAmountChange}
                onBlur={formik.handleBlur}
                error={formik.touched.salario && Boolean(formik.errors.salario)}
                helperText={formik.touched.salario && formik.errors.salario}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Sexo"
                name="sexo"
                value={formik.values.sexo}
                onChange={formik.handleChange}
              >
                <MenuItem value="1">Masculino</MenuItem>
                <MenuItem value="2">Feminino</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={close}>Cancelar</Button>
        <Button
          variant="contained"
          onClick={formik.submitForm}
          sx={{ backgroundColor: '#00294b', borderRadius: '20px' }}
          disabled={isLoading || !formik.isValid}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
