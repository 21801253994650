import { createContext, useContext, useEffect, useState } from 'react';

import { getUserByCpf } from 'services/usuariosServices';

import { useToast } from './Toast';

const StepsContext = createContext();

export function StepsProvider({ children }) {
  const { toast } = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const [formStep, setFormStep] = useState(0);
  const [page, setPage] = useState('confirmacao');
  const [statusCode, setStatusCode] = useState('');

  const openPageStatus = (page) => {
    setPage(page);
  };

  const completeFormStep = () => {
    setFormStep(formStep + 1);
  };

  const selectStep = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    if (activeStep === 3) {
      setActiveStep(1);
    } else {
      setActiveStep(activeStep - 1);
    }
    // setPage(steps[activeStep - 1].page);
  };

  const handleNext = () => {
    if (statusCode === 875 && activeStep === 1) {
      setActiveStep(3);
    } else {
      setActiveStep(activeStep + 1);
    }
    // setPage(steps[activeStep + 1].page);
  };

  const isCompletedSteps = (step) => {
    if (step < activeStep) {
      return true;
    }

    return false;
  };

  const handleAprovado = () => {
    setActiveStep(4);
  };

  const selecionarTributacao = () => {
    toast.error('Por favor, selecione uma opção.', { id: 'tributacao' });
  };

  const fetchData = async (cpf) => {
    const data = await getUserByCpf(cpf.replaceAll(/\D/g, ''));
    sessionStorage.setItem('@user:dados', JSON.stringify(data));

    if (data.status > 870) {
      if (data.status === 875) {
        setActiveStep(3);
      } else {
        setActiveStep(2);
      }
    } else {
      setActiveStep(0);
    }
  };

  useEffect(() => {
    const dados = JSON.parse(sessionStorage.getItem('@user:dados'));
    if (dados) {
      fetchData(dados.cpf);
    }
  }, []);

  return (
    <StepsContext.Provider
      value={{
        activeStep,
        setActiveStep,
        selectStep,
        formStep,
        setFormStep,
        completeFormStep,
        handleBack,
        handleNext,
        selecionarTributacao,
        isCompletedSteps,
        page,
        setPage,
        openPageStatus,
        handleAprovado,
        setStatusCode,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
}

export function useSteps() {
  const context = useContext(StepsContext);
  if (!context) {
    throw new Error('useSteps must be used within a StepsProvider');
  }
  return context;
}
