import { api } from './api';

export async function getProspects() {
  const { data } = await api.get(`/prospects`);
  return data;
}

export async function getOneProspect(id) {
  const { data } = await api.get(`/prospects/${id}`);
  return data;
}

export async function saveProspect(payload) {
  const { data } = await api.post(`/prospects`, payload);
  return data;
}

export async function updateProspect(payload) {
  const { data } = await api.put(`/prospects`, payload);
  return data;
}

export async function importProspect(payload) {
  const { data } = await api.post(`/prospects/import`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function deleteProspect(numCpf) {
  const { data } = await api.delete(`/prospects/${numCpf}`);
  return data;
}
