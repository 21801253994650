import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useResponsive } from 'hook/useResponsive';

import { Button } from 'components/Button';

import appStoreImg from 'assets/images/apple_store.svg';
import copyImg from 'assets/images/copy.svg';
import donwloadImg from 'assets/images/download.svg';
import playStoreImg from 'assets/images/play_store.svg';
import qrCode from 'assets/images/qrcode.svg';
import sendEmailImg from 'assets/images/send-email.svg';
import sendImg from 'assets/images/send.svg';

import {
  BaixeAppContainer,
  Container,
  ContainerAprovado,
  ContainerButton,
  ContainerInformacoes,
  ContainerLojasApp,
  Image,
  MatriculaContainer,
  NumeroMatricula,
  QrCodeContainer,
  SenhaPetrosContainer,
  TitleContainer,
  Wrapper,
} from './styles';

export function AprovadoMobile(props) {
  const { mobileLayout } = useResponsive();
  const [copied, setCopied] = useState(false);
  // const [matricula, setMatricula] = useState('');
  // const [numeroPedido, setNumeroPedido] = useState('');
  // const [dataNascimento, setDataNascimento] = useState('');
  // const [cpf, setCpf] = useState('');

  /* const verificaDados = async () => {
    const { numPedido, numIdPf } = JSON.parse(
      sessionStorage.getItem('@user:dados')
    )
      ? JSON.parse(sessionStorage.getItem('@user:dados'))
      : null;

    const user = await getOneUser(numPedido, numIdPf);
    const { dtNascimento, cpf } = user;

    setNumeroPedido(numPedido);
    setDataNascimento(dtNascimento);
    setCpf(cpf);

    const { numMatriculaPetros } = await getCienciaPatrocinadora(numPedido);

    setMatricula(numMatriculaPetros);
  }; */

  useEffect(() => {
    // verificaDados();
  }, []);

  const AppleStoreLink = () => {
    window.open(
      'https://apps.apple.com/br/app/petros-app/id1440476590',
      '_blank'
    );
  };

  const PlayStoreLink = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.petros&hl=pt_BR&gl=US',
      '_blank'
    );
  };

  const portalPetros = () => {
    window.open('https://www.petros.com.br/PortalPetros/', '_blank');
  };

  const handleDonwload = () => {
    // const urlDownload = `http://hmgadf.petros.com.br/InscricaoAPI/rest/public/pi/986/${numeroPedido}/comprovante`;
    // window.open(urlDownload, '_blank');
  };

  const handleSendPassword = async () => {
    /* const dataNascimentoFormatada = moment(dataNascimento).format('DD/MM/YYYY');
    const cpfFormatado = cpf.replace(/\D/g, '');

    try {
      await gerarSenha(matricula, cpfFormatado, dataNascimentoFormatada);
      toast.success('Email enviado com sucesso!');
    } catch (error) {
      toast.error(error.response.data.message, {
        id: 'error-send-email',
      });
    } */
  };

  return (
    <>
      {mobileLayout && (
        <Container>
          <TitleContainer mobile>
            <h2>Seu pedido de inscrição foi aprovado</h2>
          </TitleContainer>
          <Wrapper mobile>
            <ContainerAprovado>
              <h3>Bem-vindo ao Flexprev!</h3>
              <p>
                Você agora é participante ativo do Flexprev. Acesse o kit de
                adesão para saber mais sobre o plano. No nosso portal
                (www.petros.com.br) , com sua matrícula Petros, disponível no
                quadro abaixo, você pode gerar uma senha de acesso à Área do
                Participante, onde você encontra informações relevantes sobre o
                plano e os registros de suas contribuições e da patrocinadora.
                Com a mesma senha, entre no nosso aplicativo.
              </p>

              <MatriculaContainer>
                <h3>Matricula Petros</h3>
                <CopyToClipboard
                  options={{ debug: props.debug, message: '' }}
                  // text={matricula}
                  onCopy={() => setCopied(true)}
                >
                  <NumeroMatricula>
                    {/* <h2>{matricula}</h2> */}
                    <Image src={copyImg} alt="Copiar" />
                  </NumeroMatricula>
                </CopyToClipboard>
                {copied ? <p>Texto copiado com sucesso!</p> : <></>}
              </MatriculaContainer>
              <SenhaPetrosContainer>
                <h3>Sua senha Petros</h3>

                <Button
                  senha
                  onClick={() => {
                    handleSendPassword();
                  }}
                  endIcon={<Image src={sendImg} alt="Enviar" />}
                >
                  Enviar senha por e-mail
                </Button>
              </SenhaPetrosContainer>
            </ContainerAprovado>
            <ContainerInformacoes mobile>
              <ContainerButton>
                <Button
                  kit
                  onClick={handleDonwload}
                  startIcon={<Image src={donwloadImg} alt="Download" />}
                >
                  Baixar kit de adesão
                </Button>
                <Button
                  kit
                  startIcon={
                    <Image src={sendEmailImg} alt="Enviar por Email" />
                  }
                >
                  Receber kit por e-mail
                </Button>
              </ContainerButton>
              <BaixeAppContainer>
                <h2>Baixe o nosso app</h2>
                <ContainerLojasApp mobile>
                  <Image
                    src={appStoreImg}
                    alt="Apple Store"
                    onClick={() => AppleStoreLink()}
                  />
                  <Image
                    src={playStoreImg}
                    alt="Play Store"
                    onClick={() => {
                      PlayStoreLink();
                    }}
                  />
                </ContainerLojasApp>
                <QrCodeContainer>
                  <img src={qrCode} alt="QrCode" />
                </QrCodeContainer>
              </BaixeAppContainer>

              <ContainerButton>
                <Button
                  normal
                  onClick={() => {
                    portalPetros();
                  }}
                >
                  Ir para o Portal Petros
                </Button>
              </ContainerButton>
            </ContainerInformacoes>
          </Wrapper>
        </Container>
      )}
    </>
  );
}
