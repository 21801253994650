import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// import { api } from 'services/api';
import { postLogin } from 'services/loginServices';
// import { getAccessToken } from 'services/sensediaServices';
import { getAuth } from 'services/tokenService';

import { useSteps } from './Steps';
import { useToast } from './Toast';

const Auth = createContext();

export function AuthProvider({ children }) {
  const { toast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { setActiveStep } = useSteps();
  const [travarCadastro, setTravarCadastro] = useState();

  const loginAuth = async ({ email, senha }) => {
    const loading = toast.loading('Fazendo Login');
    setLoading(true);
    try {
      const body = { email, senha };
      const { jwtToken, dadosUser } = await postLogin(body);
      console.log(dadosUser);

      sessionStorage.setItem('@user:dados', JSON.stringify(dadosUser));
      sessionStorage.setItem('@petros:token', JSON.stringify(jwtToken));
      // localStorage.setItem('userToken', jwtToken);

      setTravarCadastro(dadosUser.travarCadastro);

      if (dadosUser.status > 870) {
        if (dadosUser.status === 875) {
          setActiveStep(3);
        } else {
          setActiveStep(2);
        }
      } else {
        setActiveStep(0);
      }

      history.push('/form');

      const { nome } = JSON.parse(sessionStorage.getItem('@user:dados'));
      toast.success(`Seja bem-vindo! 🎉 ${nome}`, { id: 'auth' });
    } catch (error) {
      let message =
        'Não foi possível fazer login neste momento. Tente novamente mais tarde.';

      if (error.isAxiosError) {
        if (error.response?.status === 404) {
          message = 'E-mail ou senha incorretos';
        } else {
          message = error.response?.data.message ?? message;
        }
      }
      toast.error(message);

      sessionStorage.removeItem('@petros:token');
      sessionStorage.removeItem('@user:dados');
    } finally {
      toast.dismiss(loading);
      setLoading(false);
    }
  };

  function signOut() {
    sessionStorage.removeItem('@petros:token');
    sessionStorage.removeItem('@petros:profile');
    sessionStorage.removeItem('@user:dados');
    localStorage.removeItem('userToken');
    setTravarCadastro(null);

    history.push('/');
  }

  const isSigned = () => {
    const storagedToken = sessionStorage.getItem('@petros:token');

    if (storagedToken) {
      return true;
    }

    return false;
  };

  const portalAuth = async (tokenPortal) => {
    const authData = await getAuth(tokenPortal);

    sessionStorage.setItem('@petros:portal_token', authData);
  };

  const isPortalSigned = () => {
    const storagedToken = sessionStorage.getItem('@petros:portal_token');

    if (storagedToken) {
      return true;
    }

    return false;
  };

  const getCpf = () => {
    const storagedUser = sessionStorage.getItem('@user:dados');

    if (storagedUser) {
      const user = JSON.parse(storagedUser);

      return user.cpf.replaceAll(/\D/g, '');
    }
  };

  const getNumPedido = () => {
    const storagedUser = sessionStorage.getItem('@user:dados');

    if (storagedUser) {
      const user = JSON.parse(storagedUser);

      return user.numPedido;
    }
  };

  const getNumIdPf = () => {
    const storagedUser = sessionStorage.getItem('@user:dados');
    if (storagedUser) {
      const user = JSON.parse(storagedUser);

      return user.numIdPf;
    }
  };

  const getStatus = () => {
    const storagedUser = sessionStorage.getItem('@user:dados');
    if (storagedUser) {
      const user = JSON.parse(storagedUser);

      return user.status;
    }
  };

  useEffect(() => {
    /* const storagedToken = sessionStorage.getItem('@petros:token');
    getAccessToken();

    if (storagedToken) {
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    } */
  }, []);

  return (
    <Auth.Provider
      value={{
        numPedido: getNumPedido(),
        numIdPf: getNumIdPf(),
        loginAuth,
        portalAuth,
        signed: isSigned(),
        portalSigned: isPortalSigned(),
        signOut,
        travarCadastro,
        setTravarCadastro,
        cpf: getCpf(),
        status: getStatus(),
        loading,
      }}
    >
      {children}
    </Auth.Provider>
  );
}

export function useAuth() {
  const context = useContext(Auth);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
