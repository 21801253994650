import { useEffect, useState } from "react";

export default function useCapsLock() {
    const [isCapsLockOn, setIsCpasLockOn] = useState(false);

    const handleKeyDown = (event) => {
        if (event.getModifierState('CapsLock')) {
            setIsCpasLockOn(true);
        } else {
            setIsCpasLockOn(false);
        }
    };

    const handleKeyUp = (event) => {
        if (!event.getModifierState('CapsLock')) {
            setIsCpasLockOn(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

    return { isCapsLockOn };
}