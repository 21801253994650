/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, Grid, TextField } from '@mui/material';

import { useFormik } from 'formik';
import { useQueryRouter } from 'hook/useQueryRouter';
import { useResponsive } from 'hook/useResponsive';
import { alterarSenha, resetSenha } from 'services/loginServices';
import { alterarEmail, getUserByCpf } from 'services/usuariosServices';
import formataCpf from 'util/formatarCPF';
import maskEmail from 'util/maskEmail';
import * as yup from 'yup';

import { Button } from 'components/Button';
import ColorButton from 'components/ColorButton/ColorButton';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';

import { useModal } from 'context/Modal';
import { useToast } from 'context/Toast';

import {
  Descricao,
  Footer,
  LogoImage,
  ModalWrapper,
  TextContainer,
  Title,
} from './styles';

export function ModalSenha() {
  const {
    showModal,
    handleCloseModal,
    handleChangeEmail,
    setShowModal,
    setShowModalSucesso,
  } = useModal();

  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingSalvarEmail, setLoadingSalvarEmail] = useState(false);
  const [isAlterarEmail, setAlterarEmail] = useState(false);
  const [cadastro, setCadastro] = useState();

  useEffect(() => {
    if (!showModal) {
      setLoading(false);
      setLoadingSalvarEmail(false);
      setAlterarEmail(false);
      setCadastro();
      formik.resetForm();
    }
  }, [showModal]);

  const formik = useFormik({
    initialValues: {
      cpf: '',
    },
    validationSchema: yup.object({
      cpf: yup
        .string()
        .required('CPF é obrigatório')
        .transform((value) => value.replace(/\D/g, ''))
        .test({
          test: (value) => /^\d{11}$/.test(value),
          message: 'CPF inválido',
        }),
    }),
    onSubmit: async (values) => {
      const loadingToast = toast.loading('Verificando cadastro');
      setLoading(true);
      try {
        const data = await getUserByCpf(values.cpf.replaceAll(/\D/g, ''));
        // await enviarRecuperacaoSenha(values);
        if (data) {
          setCadastro(data);
          formikEnviarEmail.setFieldValue('email', data.email);
        }
      } catch (error) {
        if (error.isAxiosError && error.response?.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      } finally {
        toast.dismiss(loadingToast);
        setLoading(false);
      }
    },
  });

  const formikEnviarEmail = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required('E-mail é obrigatório')
        .email('E-mail inválido'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await resetSenha(cadastro.cpf);
        handleChangeEmail(values.email);
        setShowModal(false);
        setShowModalSucesso(true);
      } catch (error) {
        console.error(error);
        toast.error('Não possível enviar o e-mail');
      } finally {
        setLoading(false);
      }
    },
  });

  const salvarEmail = async () => {
    try {
      setLoadingSalvarEmail(true);
      await alterarEmail(cadastro.cpf, formikEnviarEmail.values.email);
      setAlterarEmail(false);
    } catch (error) {
      const message =
        'Não foi possível alterar o e-mail, tente novamente em alguns instantes';
      toast.error(error.response?.data.message ?? message);
    } finally {
      setLoadingSalvarEmail(false);
    }
  };

  return (
    <Dialog fullWidth open={showModal} onClose={handleCloseModal}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <Box textAlign="center">
          <img src="logo2.png" alt="logo petros" width={120} />
        </Box>
        {!cadastro && (
          <>
            <div>
              <h3
                style={{
                  color: '#000000',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                Esqueceu sua senha?
              </h3>
              <p style={{ textAlign: 'center' }}>
                Insira seu CPF e nos ajude a identificá-lo
              </p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="CPF"
                    name="cpf"
                    type="text"
                    inputProps={{ maxLength: 14 }}
                    value={formik.values.cpf}
                    onChange={(e) => {
                      formik.setFieldValue('cpf', formataCpf(e.target.value));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Button
                    fullWidth
                    onClick={handleCloseModal}
                    sx={{ borderRadius: '20px' }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    color="secondary"
                    sx={{ borderRadius: '20px', textTransform: 'none' }}
                  >
                    Avançar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
        {cadastro && (
          <>
            <p style={{ textAlign: 'center' }}>
              Um link de redefinição de senha será enviado para o e-mail:
            </p>
            <form onSubmit={formikEnviarEmail.handleSubmit}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="E-mail"
                    name="email"
                    type="email"
                    value={formikEnviarEmail.values.email}
                    onChange={formikEnviarEmail.handleChange}
                    onBlur={formikEnviarEmail.handleBlur}
                    error={
                      formikEnviarEmail.touched.email &&
                      Boolean(formikEnviarEmail.errors.email)
                    }
                    helperText={
                      formikEnviarEmail.touched.email &&
                      formikEnviarEmail.errors.email
                    }
                    disabled={!isAlterarEmail}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                  />
                </Grid>
                <Grid item>
                  {!isAlterarEmail && (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setAlterarEmail(true);
                      }}
                    >
                      Alterar e-mail
                    </Button>
                  )}
                  {isAlterarEmail && (
                    <Button
                      variant="contained"
                      size="small"
                      disabled={
                        Boolean(formikEnviarEmail.errors.email) ||
                        loadingSalvarEmail
                      }
                      onClick={salvarEmail}
                    >
                      Salvar
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid container marginTop={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={handleCloseModal}
                    sx={{ borderRadius: '20px' }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    type="submit"
                    loading={loading}
                    disabled={isAlterarEmail}
                    color="secondary"
                    sx={{ borderRadius: '20px', textTransform: 'none' }}
                  >
                    Enviar
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export function ModalSenhaSucesso() {
  const { showModalSucesso, handleCloseModalSucesso, openModalEmail } =
    useModal();
  const { mobileLayout } = useResponsive();

  return (
    <>
      {showModalSucesso && (
        <Dialog
          onClose={handleCloseModalSucesso}
          aria-labelledby="simple-dialog-title"
          open={showModalSucesso}
          scroll="body"
          fullWidth
        >
          {!mobileLayout && (
            <ModalWrapper>
              <LogoImage alt="Logo Petros" src="./logo2.png" />
              <TextContainer enviado>
                <Title>E-mail enviado com sucesso!</Title>
                <LogoImage alt="Logo Petros" src="./send.png" />
                <br />
                <Descricao>
                  Verifique sua caixa de entrada para redefinir a sua senha
                </Descricao>
              </TextContainer>

              <Footer>
                <Button normal onClick={() => openModalEmail()}>
                  Avançar
                </Button>
              </Footer>
            </ModalWrapper>
          )}

          {mobileLayout && (
            <ModalWrapper mobile>
              <LogoImage alt="Logo Petros" src="./logo2.png" />
              <TextContainer mobileEnviado>
                <Title mobile>E-mail enviado com sucesso!</Title>
                <LogoImage alt="Logo Petros" src="./send.png" />
                <br />
                <Descricao mobile>
                  Verifique sua caixa de entrada para redefinir a sua senha
                </Descricao>
              </TextContainer>

              <Footer>
                <Button normal onClick={() => openModalEmail()}>
                  Avançar
                </Button>
              </Footer>
            </ModalWrapper>
          )}
        </Dialog>
      )}
    </>
  );
}

export function ModalSenhaEmail() {
  const { handleCloseModalEmail, showModalEmail, email } = useModal();
  const { mobileLayout } = useResponsive();

  return (
    <>
      {showModalEmail && (
        <Dialog
          onClose={handleCloseModalEmail}
          aria-labelledby="simple-dialog-title"
          open={showModalEmail}
          scroll="body"
          fullWidth
        >
          {!mobileLayout && (
            <ModalWrapper>
              <LogoImage alt="Logo Petros" src="./logo2.png" />
              <TextContainer enviado>
                <Title>
                  Enviamos uma mensagem para o e-mail!
                  <br />
                  {maskEmail(email)}
                </Title>
                <br />
                <Descricao>
                  Confira sua caixa de e-mail para prosseguir com sua
                  <br /> inscrição no Flexprev
                </Descricao>
              </TextContainer>

              <Footer>
                <Button outlinedVinculados onClick={handleCloseModalEmail}>
                  Fechar
                </Button>
              </Footer>
            </ModalWrapper>
          )}

          {mobileLayout && (
            <ModalWrapper mobile>
              <LogoImage alt="Logo Petros" src="./logo2.png" />
              <TextContainer mobileEnviado>
                <Title mobile>E-mail enviado com sucesso!</Title>
                <LogoImage alt="Logo Petros" src="./send.png" />
                <br />
                <Descricao mobile>
                  Verifique sua caixa de entrada para redefinir a sua senha
                </Descricao>
              </TextContainer>

              <Footer>
                <Button normal onClick={() => handleCloseModalEmail()}>
                  Avançar
                </Button>
              </Footer>
            </ModalWrapper>
          )}
        </Dialog>
      )}
    </>
  );
}

export function ModalRedefinirSenha() {
  const { toast } = useToast();
  const { handleCloseModalRedefinirSenha, showModalRedefinirSenha } =
    useModal();
  const query = useQueryRouter();
  const token = query.get('token');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      senha: '',
      confirmacaoDeSenha: '',
    },
    validationSchema: yup.object({
      senha: yup
        .string()
        .required('Senha é obrigatório')
        .min(6, 'A senha precisa ter pelo menos 6 caracteres'),
      confirmacaoDeSenha: yup
        .string()
        .required('Confirmação de senha é obrigatório')
        .oneOf([yup.ref('senha')], 'As senhas não coincidem'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = { senha: values.senha, token };
        await alterarSenha(body);
        handleCloseModalRedefinirSenha();
        toast.success('Senha redefinida com sucesso');
      } catch (error) {
        console.error(error);
        const message = 'Não possível redefinir a senha';
        toast.error(error.response?.data.message ?? message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      {showModalRedefinirSenha && (
        <Dialog
          fullWidth
          onClose={handleCloseModalRedefinirSenha}
          open={showModalRedefinirSenha}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src="logo2.png" alt="logo petros" width={120} />
            </Box>
            <h3
              style={{
                color: '#000000',
                textAlign: 'center',
              }}
            >
              Redefinir senha
            </h3>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PasswordInput
                    variant="outlined"
                    name="senha"
                    label="Senha"
                    value={formik.values.senha}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.senha && Boolean(formik.errors.senha)}
                    helperText={formik.touched.senha && formik.errors.senha}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    variant="outlined"
                    name="confirmacaoDeSenha"
                    label="Confirmação de senha"
                    value={formik.values.confirmacaoDeSenha}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmacaoDeSenha &&
                      Boolean(formik.errors.confirmacaoDeSenha)
                    }
                    helperText={
                      formik.touched.confirmacaoDeSenha &&
                      formik.errors.confirmacaoDeSenha
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <ColorButton
                    fullWidth
                    variant="contained"
                    type="submit"
                    loading={loading}
                    color="secondary"
                    sx={{ borderRadius: '20px', textTransform: 'none' }}
                  >
                    Avançar
                  </ColorButton>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
