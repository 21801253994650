import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { useQueryRouter } from 'hook/useQueryRouter';
import { validarPrimeiroAcesso } from 'services/loginServices';

export function ValidacaoEmail() {
  const query = useQueryRouter();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    const validarEmail = () => {
      setTimeout(async () => {
        try {
          await validarPrimeiroAcesso(query.get('confirmationToken'));
          setTitle('Email confirmado!');
        } catch (error) {
          setTitle('Ops!');
          const message =
            'Houve um problema na confirmação do e-mail. Por favor, tente novamente em alguns instantes';
          setMessage(error.response?.data.message ?? message);
        } finally {
          setLoading(false);
        }
      }, 1000);
    };

    if (query.has('confirmationToken')) {
      setTitle('Confirmando e-mail');
      setLoading(true);
      validarEmail();
    }
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{title}</h1>
      {loading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <p>
          {message ?? (
            <>
              <Link to="/">Clique aqui</Link> para voltar ao sistema.
            </>
          )}
        </p>
      )}
    </div>
  );
}
