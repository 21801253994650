import { ConcluirInscricao } from 'pages/ConcluirInscricao';
import { Status } from 'pages/Status';

import { useAuth } from 'context/Auth';

export function StatusEConfirmacao() {
  const { travarCadastro } = useAuth();

  return <>{travarCadastro == 2 ? <Status /> : <ConcluirInscricao />}</>;
}
