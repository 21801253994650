import { createContext, useContext, useState } from 'react';

import { useAuth } from './Auth';
import { useModal } from './Modal';
import { useToast } from './Toast';

const ContribuicaoContext = createContext();

export function ContribuicaoProvider({ children }) {
  const { toast } = useToast();
  const { openModalContribuicao } = useModal();
  const { travarCadastro } = useAuth();

  // BUG_FIX
  // 1 - N„o est· deixando imputar sal·rio quando o prospect n„o est· na base da patrocinadora (isso imposibilitou os demais testes nessa tela)
  // 2 - A conbribuiÁ„o total est· carregando com mensagem de erro "NaN%"
  // Author: Lemuel
  const [temSalario, setTemSalario] = useState(false);

  const [salario, setSalario] = useState(0);

  const [porcentagem1, setPorcentagem1] = useState(0);
  const [porcentagem2, setPorcentagem2] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const calcularPorcentagem = (salario) => {
    if (salario >= 0 && salario <= 6090.3) {
      return 5;
    }

    return 11;
  };

  const handleChangePorcentagem1 = (percent) => {
    const limitePorcentagem = calcularPorcentagem(salario);

    if (percent < limitePorcentagem) {
      setPorcentagem2(0);
    }

    if (percent > limitePorcentagem) {
      setPorcentagem1(limitePorcentagem);
      return;
    }

    setPorcentagem1(percent);
  };

  const handleChangePorcentagem2 = (percent) => {
    setPorcentagem2(percent);
  };

  const limitePorcentagem2 = () => {
    const limitePorcentagem = calcularPorcentagem(salario);

    return 25 - limitePorcentagem;
  };

  const calcularSalario = (porcentagem) => salario * (porcentagem / 100);

  const handleOpenModalOuProximaTela = () => {
    if (salario == '' || salario == null) {
      toast.error('O campo salário não foi preenchido.', {
        id: 'contribuicao',
      });
      return;
    }
    if (porcentagem1 <= 0) {
      toast.error('A contribuição deve ser de pelo menos 1%', {
        id: 'contribuicao',
      });
      return;
    }

    openModalContribuicao();
  };

  const atualizarContribuicao = async () => {
    const getDados = JSON.parse(sessionStorage.getItem('@user:dados'));

    getDados.salario = salario;
    getDados.contribuicaoBasica = porcentagem1;
    getDados.contribuicaoVoluntaria = porcentagem2;

    sessionStorage.setItem('@user:dados', JSON.stringify(getDados));
  };

  const handleLoadContribuicao = () => {
    setIsLoading(true);
    const loading = toast.loading('Carregando formulário');

    const dados = JSON.parse(sessionStorage.getItem('@user:dados'));
    setPorcentagem1(dados.contribuicaoBasica ?? 0);
    setPorcentagem2(dados.contribuicaoVoluntaria ?? 0);
    setSalario(dados.salario ?? 0);

    setIsLoading(false);
    toast.dismiss(loading);
  };

  const verificarContribuicao = () => {
    if (temSalario || isLoading) {
      return true;
    }
    return false;
  };

  const isDisabledAll = () => {
    if (travarCadastro == 2 || isLoading) {
      return true;
    }
    return false;
  };

  const naoTrava = () => {
    if (travarCadastro == 0 || isLoading || travarCadastro == null) {
      return false;
    }
    return false;
  };

  return (
    <ContribuicaoContext.Provider
      value={{
        salario,
        setSalario,
        porcentagem1,
        porcentagem2,
        setPorcentagem1,
        setPorcentagem2,
        calcularPorcentagem,
        handleChangePorcentagem1,
        handleChangePorcentagem2,
        limitePorcentagem2,
        calcularSalario,
        handleOpenModalOuProximaTela,
        atualizarContribuicao,
        handleLoadContribuicao,
        disabled: verificarContribuicao(),
        disabledAll: isDisabledAll(),
        naoDesabilita: naoTrava(),
        temSalario,
        setTemSalario,
      }}
    >
      {children}
    </ContribuicaoContext.Provider>
  );
}

export function useContribuicao() {
  const context = useContext(ContribuicaoContext);
  if (!context) {
    throw new Error(
      'useContribuicao must be used within a ContribuicaoProvider'
    );
  }
  return context;
}
