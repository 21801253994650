import { Dialog } from '@mui/material';

import { Button } from 'components/Button';

import { useModal } from 'context/Modal';

import logoImg from 'assets/images/logo2.png';

import { Footer, LogoImage, ModalWrapper, TextContainer } from './styles';

export function ModalAvisoAlteracao() {
  const { handleCloseModalAvisoAlteracao, showModalAvisoAlteracao } =
    useModal();

  return (
    <Dialog
      onClose={handleCloseModalAvisoAlteracao}
      aria-labelledby="simple-dialog-title"
      open={showModalAvisoAlteracao}
      scroll="body"
      fullWidth
    >
      <ModalWrapper>
        <LogoImage src={logoImg} alt="Logo Petros" />
        <h2>Processando alterações</h2>
        <TextContainer style={{ marginTop: '-3em' }}>
          <p>As alterações realizadas estão sendo processadas</p>
          Volte mais tarde para continuar a inscrição
        </TextContainer>
        <Footer>
          <Button
            style={{ width: '159px !important' }}
            normal
            onClick={() => {
              handleCloseModalAvisoAlteracao();
            }}
          >
            Continuar
          </Button>
        </Footer>
      </ModalWrapper>
    </Dialog>
  );
}
