import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useModal } from 'context/Modal';

import logoImg from 'assets/images/logo2.png';

import { LogoImage } from './styles';

export function ModalProcessandoInscricao() {
  const {
    handleCloseModalProcessandoInscricao,
    showModalProcessandoInscricao,
  } = useModal();

  return (
    <Dialog
      onClose={handleCloseModalProcessandoInscricao}
      aria-labelledby="simple-dialog-title"
      open={showModalProcessandoInscricao}
      scroll="body"
      fullWidth
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <LogoImage src={logoImg} alt="Logo Petros" />
        <p>
          A solicitação está sendo processada, a página será atualizada
          automaticamente.
        </p>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <CircularProgress />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
