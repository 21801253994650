import { useResponsive } from 'hook/useResponsive';

import { Aprovado } from 'pages/Aprovado';
import { Contribuicao } from 'pages/Contribuicao';
import { Formulario } from 'pages/Formulario';
import { Info } from 'pages/Info';
import { StatusEConfirmacao } from 'pages/StatusEConfirmacao';
// import { Vinculados } from 'pages/Vinculados';

import { Header } from 'components/Header';
import { Layout } from 'components/Layout';

import { useSteps } from 'context/Steps';

function getStepContent(step) {
  console.log(step);
  switch (step) {
    case 0:
      return <Formulario />;
    case 1:
      return <Contribuicao />;
    case 2:
      return <StatusEConfirmacao />;
    case 3:
      return <Aprovado />;
    default:
      return <Info />;
  }
}

export function Form() {
  const { mobileLayout } = useResponsive();
  const { activeStep } = useSteps();

  return (
    <>
      {!mobileLayout && (
        <Layout>
          <Header />
          <>{getStepContent(activeStep)}</>
        </Layout>
      )}

      {mobileLayout && (
        <Layout>
          <>{getStepContent(activeStep)}</>
        </Layout>
      )}
    </>
  );
}
