import { useEffect } from 'react';
import Files from 'react-files';
import { MdAddCircle } from 'react-icons/md';

import Autocomplete from '@mui/material/Autocomplete';

import { getProspects } from 'services/prospectServices';
// import { getAccessToken } from 'services/sensediaServices';

import { Button } from 'components/Button';
import { ModalCadastro } from 'components/ModalCadastro';
import TabelaProspect from 'components/TableProspect';

import { useModalProspect } from 'context/ModalProspect';
import { useProspect } from 'context/Prospects';

import { ContainerButton, Search, ContainerTitleSearch } from './styles';

export function Prospect() {
  const {
    listaProspect,
    setListaProspect,
    matriculas,
    getListaProspects,
    onFileChangeProspectos,
  } = useProspect();

  const { openModalCadastro } = useModalProspect();

  const fetchLista = async () => {
    // await getAccessToken();
    getListaProspects();
  };

  useEffect(() => {
    fetchLista();
  }, []);

  return (
    <>
      <ContainerTitleSearch>
        <h1>Cadastro de Prospect</h1>

        <Autocomplete
          id="combo-box-demo"
          freeSolo
          clearOnBlur
          options={matriculas}
          onChange={async (e, newValue) => {
            if (newValue != null) {
              setListaProspect(
                listaProspect.filter(
                  (prospect) => prospect.numMatricula === newValue
                )
              );
            } else {
              const prospects = await getProspects();
              setListaProspect(prospects || []);
            }
          }}
          getOptionLabel={(professor) => professor}
          style={{ width: 300 }}
          renderInput={(params) => (
            <Search
              {...params}
              label="Buscar por matricula"
              variant="outlined"
            />
          )}
        />
      </ContainerTitleSearch>

      <ContainerButton>
        <Button
          normal
          onClick={() => {
            openModalCadastro();
          }}
        >
          Cadastrar
        </Button>
        <Files
          onChange={onFileChangeProspectos}
          accepts={['.csv']}
          multiple={false}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <Button
            iconeOutlined
            style={{ marginLeft: '16px' }}
            endIcon={<MdAddCircle size={24} color="#00887D" />}
          >
            {' '}
            Importar Prospect{' '}
          </Button>
        </Files>
      </ContainerButton>

      {listaProspect.length > 0 && <TabelaProspect rows={listaProspect} />}
      <ModalCadastro />
    </>
  );
}
