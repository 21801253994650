import { api } from './api';

export function setupToken(token) {
  api.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
}

export async function postLogin(body) {
  const response = await api.post('/users/authenticate', body);
  return response.data;
}

export async function postPreCadastro(body) {
  const response = await api.post('/users/pre-cadastro', body);
  return response;
}

export async function getUserByEmail(email) {
  const { data } = await api.get(`/users/findbyemail/${email}`);
  return data;
}

export async function getUsuarioCadastradoPorCpf(cpf) {
  const { data } = await api.get(`users/usuario-cadastrado/${cpf}`);
  return data;
}

export async function buscarCadastroParaResetarSenha(body) {
  const params = new URLSearchParams();
  params.append('cpf', body.cpf);
  params.append('dataNascimento', body.dataNascimento);
  const { data } = await api.get(`/users/pedido/email`, { params });
  return data;
}

export async function resetSenha(cpf) {
  const { data } = await api.post(`/users/senha/reset?cpf=${cpf}`);
  return data;
}

export async function getTravarUser(numPedido) {
  const { data } = await api.get(`/users/statusCadastro/${numPedido}`);

  return data;
}

export const getUserVerifyCpf = async (cpf) => {
  const { data } = await api.get(`/verifica-contrato/${cpf}`);
  return data;
};

export const alterarSenha = async (body) => {
  const { data } = await api.put('/users/alterarSenha', body);
  return data;
};

export const validarPrimeiroAcesso = async (token) => {
  await api.get(`/users/validate/${token}`);
};
