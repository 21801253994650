/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect } from 'react';
import { buildStyles } from 'react-circular-progressbar';

import { InputAdornment, TextField } from '@mui/material';

import { useResponsive } from 'hook/useResponsive';
import { formatarSalario } from 'util/formatarSalario';

import { Button } from 'components/Button';
import { ModalAvisoAlteracao } from 'components/ModalAvisoAlteracao';
import { ModalContribuicao } from 'components/ModalContribuicao';

import { useContribuicao } from 'context/Contribuicao';
import { useModal } from 'context/Modal';

import checkIcon from 'assets/images/check.png';

// import checkMarkLock from 'assets/images/checkmark_lock.svg';
import 'react-circular-progressbar/dist/styles.css';

import { ContribuicaoMobile } from './mobile';
import {
  Card,
  CircularProgressbarText,
  Container,
  ContainerCard,
  ContainerCircularProgressBar,
  ContainerContribuicao,
  ContainerLeft,
  ContainerMid,
  ContainerRight,
  ContainerSlider,
  ContainerThumb,
  ContribuicaoBasica,
  ContribuicaoBasicaTitle,
  ContribuicaoParticipante,
  ContribuicaoVoluntaria,
  DescricaoText,
  DinheiroText,
  Footer,
  FooterText,
  ImageProfile,
  PorcentagemText,
  StyledCircular,
  StyledSlider,
  StyledThumb,
  StyledThumbDisabled,
  StyledThumbDisabledText,
  StyledTrack,
  TitleContainer,
} from './styles';

const defaultValue = (state, limitePorcentagem = 0) => {
  const valor = state.valueNow;
  if (state.valueNow == state.value[1]) {
    if (state.value[0] < limitePorcentagem) {
      return state.value[0];
    }

    return state.valueNow - 5;
  }

  return valor;
};

function Thumb({ props, state, limitePorcentagem }) {
  return (
    <StyledThumb {...props}>
      {defaultValue(state, limitePorcentagem)}%
    </StyledThumb>
  );
}

function Track(props, state) {
  return <StyledTrack {...props} index={state.index} voluntaria />;
}

export function Contribuicao() {
  const { mobileLayout } = useResponsive();
  const {
    salario,
    setSalario,
    porcentagem1,
    porcentagem2,
    setPorcentagem1,
    setPorcentagem2,
    calcularPorcentagem,
    handleChangePorcentagem1,
    handleChangePorcentagem2,
    limitePorcentagem2,
    calcularSalario,
    handleOpenModalOuProximaTela,
    handleLoadContribuicao,
    disabled,
    disabledAll,
    naoDesabilita,
  } = useContribuicao();
  const {
    showModalContribuicao,
    setShowModalContribuicao,
    showModalAvisoAlteracao,
    setShowModalAvisoAlteracao,
  } = useModal();

  useEffect(() => {
    handleLoadContribuicao();
  }, []);

  const handleAmountChange = (event) => {
    const rawValue = event.target.value.replace(/[^0-9]/g, '');
    const numericValue = parseFloat(rawValue) / 100;
    setSalario(numericValue);
    const porcentagem = calcularPorcentagem(numericValue);
    if (porcentagem1 > porcentagem) {
      setPorcentagem1(porcentagem);
    } else if (porcentagem1 < porcentagem) {
      setPorcentagem2(0);
    }
  };

  return (
    <>
      {!mobileLayout && (
        <Container>
          <TitleContainer>
            <h2>Informe seu salário e defina o percentual de contribuição</h2>
          </TitleContainer>

          <ContainerMid>
            <ContainerLeft>
              <TextField
                id="outlined-basic"
                label="Salário"
                variant="outlined"
                type="text"
                disabled={
                  (naoDesabilita == false ? false : disabled) || disabledAll
                }
                value={formatarSalario(salario)}
                error={!salario}
                onChange={handleAmountChange}
                inputProps={{ maxLength: 13 }}
                InputProps={{
                  endAdornment: (
                    <>
                      {(disabled || disabledAll) && Boolean(salario) && (
                        <InputAdornment position="end">
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
              <ContainerContribuicao>
                <ContribuicaoBasicaTitle>
                  Contribuição básica
                </ContribuicaoBasicaTitle>
                <ContribuicaoBasica>
                  <p>
                    É feita mensalmente por você e acompanhada pela Vibra, que
                    depositará, todo mês, o mesmo percentual em seu nome. Assim,
                    seu investimento dobra de imediato. O percentual disponível
                    varia de acordo com a faixa salarial: de 1% a 5% ou de 1% a
                    11%.
                  </p>
                  <ContribuicaoParticipante>
                    <ContainerThumb>
                      <StyledThumbDisabled>
                        <StyledThumbDisabledText>
                          {calcularPorcentagem(salario)}%
                        </StyledThumbDisabledText>
                      </StyledThumbDisabled>
                    </ContainerThumb>

                    <ContainerSlider>
                      <StyledSlider
                        value={porcentagem1}
                        max={calcularPorcentagem(salario)}
                        disabled={!salario || disabledAll}
                        renderTrack={Track}
                        renderThumb={(props, state) => (
                          <Thumb
                            props={props}
                            state={state}
                            limitePorcentagem={calcularPorcentagem(salario)}
                          />
                        )}
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        onChange={(porcentagem1) => {
                          handleChangePorcentagem1(porcentagem1);
                        }}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={(state) =>
                          `Thumb value ${state.valueNow}`
                        }
                        ariaLabelledby={[
                          'first-slider-label',
                          'second-slider-label',
                        ]}
                        snapDragDisabled
                      />
                    </ContainerSlider>
                  </ContribuicaoParticipante>
                </ContribuicaoBasica>
                <ContribuicaoBasicaTitle>
                  Contribuição voluntária
                </ContribuicaoBasicaTitle>
                <ContribuicaoVoluntaria>
                  <p>
                    Se você optar pelo percentual máximo da contribuição básica
                    também poderá fazer contribuições mensais voluntárias, sem a
                    contrapartida da Vibra, para elevar sua conta individual e o
                    benefício futuro. A contribuição voluntária é opcional.
                  </p>

                  <ContribuicaoParticipante>
                    <ContainerThumb>
                      <StyledThumbDisabled style={{ marginTop: '41px' }}>
                        <StyledThumbDisabledText>25%</StyledThumbDisabledText>
                      </StyledThumbDisabled>
                    </ContainerThumb>

                    {(calcularPorcentagem(salario) && porcentagem1 == 5) ||
                    porcentagem1 == 11 ? (
                      <ContainerSlider style={{ marginTop: '-45px' }}>
                        <StyledSlider
                          value={porcentagem2}
                          max={25}
                          disabled={
                            !salario ||
                            porcentagem1 != calcularPorcentagem(salario) ||
                            disabledAll
                          }
                          renderTrack={Track}
                          renderThumb={(props, state) => (
                            <Thumb
                              props={props}
                              state={state}
                              limitePorcentagem={calcularPorcentagem(salario)}
                            />
                          )}
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          onChange={(porcentagem2) => {
                            handleChangePorcentagem2(porcentagem2);
                          }}
                          ariaLabel={['Lower thumb', 'Upper thumb']}
                          ariaValuetext={(state) =>
                            `Thumb value ${state.valueNow}`
                          }
                          ariaLabelledby={[
                            'first-slider-label',
                            'second-slider-label',
                          ]}
                          snapDragDisabled
                        />
                      </ContainerSlider>
                    ) : (
                      <ContainerSlider style={{ marginTop: '-45px' }}>
                        <StyledSlider
                          value={porcentagem2}
                          max={limitePorcentagem2()}
                          disabled
                          renderTrack={Track}
                          renderThumb={(props, state) => (
                            <Thumb
                              props={props}
                              state={state}
                              limitePorcentagem={calcularPorcentagem(salario)}
                            />
                          )}
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          onChange={(porcentagem2) => {
                            handleChangePorcentagem2(porcentagem2);
                          }}
                          ariaLabel={['Lower thumb', 'Upper thumb']}
                          ariaValuetext={(state) =>
                            `Thumb value ${state.valueNow}`
                          }
                          ariaLabelledby={[
                            'first-slider-label',
                            'second-slider-label',
                          ]}
                          snapDragDisabled
                        />
                      </ContainerSlider>
                    )}
                  </ContribuicaoParticipante>
                </ContribuicaoVoluntaria>
              </ContainerContribuicao>
            </ContainerLeft>
            <ContainerRight>
              <ContainerCircularProgressBar>
                <StyledCircular
                  maxValue={36}
                  styles={buildStyles({
                    pathColor: '#04cfbf',
                    textColor: '#4f4f4f',
                    trailColor: '#f2f2f2',
                  })}
                  value={parseInt(porcentagem1) + parseInt(porcentagem2)}
                  text={`${
                    Number.isNaN(
                      parseInt(porcentagem1) + parseInt(porcentagem2)
                    )
                      ? 0
                      : parseInt(porcentagem1) + parseInt(porcentagem2)
                  }%`}
                >
                  <CircularProgressbarText>
                    Contribuição total individual
                  </CircularProgressbarText>
                </StyledCircular>
              </ContainerCircularProgressBar>

              <ContainerCard>
                <Card>
                  <PorcentagemText>{porcentagem1}%</PorcentagemText>
                  <DinheiroText>
                    {porcentagem1 == 0
                      ? 'R$ 0,00'
                      : calcularSalario(porcentagem1).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                  </DinheiroText>
                  <DescricaoText>Contrubuição básica</DescricaoText>
                </Card>
                <Card>
                  <PorcentagemText>{porcentagem1}%</PorcentagemText>
                  <DinheiroText>
                    {porcentagem1 == 0
                      ? 'R$ 0,00'
                      : calcularSalario(porcentagem1).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                  </DinheiroText>
                  <DescricaoText>Contrubuição da patrocinadora</DescricaoText>
                </Card>
                <Card>
                  <PorcentagemText>{porcentagem2}%</PorcentagemText>
                  <DinheiroText>
                    {porcentagem2 == 0
                      ? 'R$ 0,00'
                      : calcularSalario(porcentagem2).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                  </DinheiroText>
                  <DescricaoText>Contrubuição voluntária</DescricaoText>
                </Card>
              </ContainerCard>
            </ContainerRight>
          </ContainerMid>
          <Footer>
            {calcularPorcentagem(salario) === porcentagem1 ? (
              <FooterText>
                <p>
                  <b>Importante:</b> todas as contribuições para previdência
                  complementar podem ser deduzidas do Imposto de Renda até o
                  limite de 12% do total dos rendimentos brutos tributáveis
                  anuais por quem utiliza o modelo completo.
                </p>

                <Button
                  normal
                  onClick={() => {
                    handleOpenModalOuProximaTela();
                  }}
                  disabled={disabledAll}
                >
                  Avançar
                </Button>
              </FooterText>
            ) : (
              <FooterText secondary>
                <p>
                  <b>Importante:</b> todas as contribuições para previdência
                  complementar podem ser deduzidas do Imposto de Renda até o
                  limite de 12% do total dos rendimentos brutos tributáveis
                  anuais por quem utiliza o modelo completo.
                </p>

                <Button
                  normal
                  onClick={() => {
                    handleOpenModalOuProximaTela();
                  }}
                  disabled={disabledAll}
                >
                  Avançar
                </Button>
              </FooterText>
            )}
          </Footer>
        </Container>
      )}

      {mobileLayout && <ContribuicaoMobile />}

      <ModalContribuicao
        showModal={showModalContribuicao}
        setShowModal={setShowModalContribuicao}
      />
      <ModalAvisoAlteracao
        showModal={showModalAvisoAlteracao}
        setShowModal={setShowModalAvisoAlteracao}
      />
    </>
  );
}
