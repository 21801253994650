import { Dialog } from '@mui/material';

import { Button } from 'components/Button';

import { useContribuicao } from 'context/Contribuicao';
import { useModal } from 'context/Modal';
import { useSteps } from 'context/Steps';

import undrawStep from 'assets/images/undraw_stepping_up.svg';

import { ModalWrapper, LogoImage, TextContainer, Footer } from './styles';

export function ModalContribuicao() {
  const { handleCloseModalContribuicao, showModalContribuicao } = useModal();
  const { handleNext } = useSteps();
  const { atualizarContribuicao } = useContribuicao();

  return (
    <Dialog
      onClose={handleCloseModalContribuicao}
      aria-labelledby="simple-dialog-title"
      open={showModalContribuicao}
      scroll="body"
      fullWidth
    >
      <ModalWrapper>
        <LogoImage src={undrawStep} alt="Undraw" />
        <TextContainer>
          <p>
            <b>Atenção:</b> todas as contribuições para previdência complementar
            podem ser deduzidas do Imposto de Renda até o limite de 12% do total
            dos rendimentos brutos tributáveis anuais por quem utiliza o modelo
            completo.
          </p>
        </TextContainer>
        <TextContainer style={{ marginTop: '-3em' }}>
          <p>
            <b>
              Lembre-se que todo dinheiro que você aplica como contribuição
              básica é acompanhado pela Vibra.
            </b>
          </p>
        </TextContainer>

        <Footer>
          <Button
            outlinedVinculados
            onClick={() => {
              handleCloseModalContribuicao();
            }}
          >
            <b>Rever %</b>
          </Button>
          <Button
            style={{ width: '159px !important' }}
            normal
            onClick={() => {
              atualizarContribuicao();
              handleNext();
              handleCloseModalContribuicao();
            }}
          >
            Avançar
          </Button>
        </Footer>
      </ModalWrapper>
    </Dialog>
  );
}
