import { api } from './api';

export async function getNacionalidades() {
  const { data } = await api.get(`/paises`);
  return data;
}

export async function getEstados(codPais) {
  const { data } = await api.get(`/paises/${codPais}/estados`);
  return data;
}

export async function getCidadesNacionalidade(codPais, codUf) {
  const { data } = await api.get(`/paises/${codPais}/estados/${codUf}/cidades`);
  return data;
}
