import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';

import useCapsLock from '../../hook/useCapsLock';

export function PasswordInput({
    variant,
    error,
    helperText,
    label,
    ...rest
}) {
    const [showPassword, setShowPassword] = useState(false);
    const { isCapsLockOn } = useCapsLock('CapsLock');

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl fullWidth variant={variant} error={error}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                sx={{ borderRadius: '12px' }}
                label={label}
                type={showPassword ? 'text' : 'password'}
                {...rest}
                onPaste={(e) => {
                    e.preventDefault();
                    return false;
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText>{helperText}</FormHelperText>
            {isCapsLockOn && <FormHelperText>CapsLock está ativado</FormHelperText>}
        </FormControl>
    );
}