import { createContext, useContext } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useAuth } from './Auth';

const LoginContext = createContext();

export function LoginProvider({ children }) {
  const { loginAuth, loading } = useAuth();

  const validationSchema = yup.object({
    email: yup
      .string()
      .required('E-mail é obrigatório')
      .email('E-mail inválido')
      .test({
        test: (value) => !value.includes('+'),
        message: 'E-mail inválido',
      }),
    senha: yup
      .string()
      .required('Senha é obrigatório')
      .min(6, 'A senha precisa ter pelo menos 6 caracteres'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      senha: '',
    },
    validationSchema,
    onSubmit: ({ email, senha }) => {
      loginAuth({ email, senha });
    },
  });

  return (
    <LoginContext.Provider
      value={{
        formik,
        loading,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLogin() {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLogin must be used within a PrimeiroAcessoProvider');
  }
  return context;
}
