import { useState } from 'react';

import { subYears } from 'date-fns';
import { useFormik } from 'formik';
import moment from 'moment';
// import { getCidades } from 'services/ibgeService';
// import { getTravarUser } from 'services/loginServices';
import {
  getCidadesNacionalidade,
  getEstados,
  getNacionalidades,
} from 'services/localidadeServices';
import { buscarPorCEP } from 'services/usuariosServices';
import calculaIdade from 'util/calcularIdade';
import formataCpf from 'util/formatarCPF';
import formatarNome from 'util/formatarNome';
import formataTelefone from 'util/formatarTelefone';
import * as yup from 'yup';

import { useAuth } from 'context/Auth';
import { useSteps } from 'context/Steps';
import { useToast } from 'context/Toast';

export function usePersonalInfoForm() {
  const { toast } = useToast();
  const { travarCadastro, setTravarCadastro } = useAuth();
  const { handleNext } = useSteps();
  const [isLoadingFormulario, setIsLoadingFormulario] = useState(false);
  const [dadosApi, setDadosApi] = useState(null);
  const [nacionalidadeLista, setNacionalidadeLista] = useState([]);
  const [estadoLista, setEstadoLista] = useState([]);
  const [cidadeLista, setCidadeLista] = useState([]);
  const [loadingCep, setLoadingCep] = useState(false);
  const [enderecoDesabilitado, setEnderecoDesabilitado] = useState(false);

  yup.addMethod(
    yup.mixed,
    'isRequiredByPessoaExposta',
    function (errorMessage) {
      return this.test(
        'is-required-by-pessoa-exposta',
        'Campo obrigatório',
        function (value) {
          const { path, createError } = this;

          if (!formik.values.pessoaExposta) {
            return true;
          }
          return value ? true : createError({ path, message: errorMessage });
        }
      );
    }
  );

  yup.addMethod(yup.date, 'isMaioridade', function (errorMessage) {
    return this.test('is-maioridade', 'Deve ser maior de idade', function () {
      const { path, createError } = this;
      const data = moment(formik.values.dataNascimento).format('DD/MM/YYYY');
      const idade = calculaIdade(data);
      if (idade > 18) {
        return true;
      }
      return createError({ path, message: errorMessage });
    });
  });

  const validationSchema = yup.object({
    nome: yup
      .string()
      .required('Nome é obrigatório')
      .matches(/^([a-zA-Z\u00C0-\u00FF]+\s)+[a-zA-Z\u00C0-\u00FF]+$/, {
        message: 'Nome inválido',
      }),
    dataNascimento: yup
      .date()
      .nullable()
      .required('Data de nascimento é obrigatório')
      .typeError('Data inválida')
      .test({
        test: (value) => value <= subYears(new Date(), 18),
        message: 'Deve ser maior de idade',
      })
      .test({
        test: (value) => value >= new Date(1900, 0, 1),
        message: 'Data de nascimento inválida',
      }),
    sexo: yup.string().required('Sexo é obrigatório'),
    cpf: yup
      .string()
      .required('CPF é obrigatório')
      .transform((value) => value.replace(/\D/g, ''))
      .test({
        test: (value) => /^\d{11}$/.test(value),
        message: 'CPF inválido',
      })
      .test({
        test: (value) => !value.split('').every((char) => char === value[0]),
        message: 'CPF inválido',
      }),
    celular: yup
      .string()
      .required('Celular é obrigatório')
      .transform((value) => value.replace(/\D/g, ''))
      .test({
        test: (value) => /^\d{11}$/.test(value),
        message: 'Celular inválido',
      })
      .test({
        test: (value) => value.substring(0, 2) !== '00',
        message: 'Celular inválido',
      }),
    email: yup
      .string()
      .required('E-mail é obrigatório')
      .email('E-mail inválido'),
    matriculaPatrocinadora: yup
      .string()
      .required('Matrícula é obrigatório')
      .test({
        test: (value) => Number(value) > 0,
        message: 'Matrícula inválida',
      }),
    dataAdmissao: yup
      .date()
      .nullable()
      .required('Data de admissão é obrigatório')
      .test({
        test: (value, context) => {
          const { dataNascimento } = context.parent;
          const diff =
            new Date(value).getFullYear() -
            new Date(dataNascimento).getFullYear();
          return diff >= 18;
        },
        message: 'Deve ser maior de idade',
      })
      .max(new Date(), 'Data de adimissão não pode ser maior que a data atual')
      .typeError('Data de admissão inválida'),
    cep: yup.string().required('CEP é obrigatório').length(8, 'CEP inválido'),
    logradouro: yup
      .string()
      .required('Logradouro é obrigatório')
      .matches(/^[A-Za-z0-9\u00C0-\u00FF \-()]+$/, {
        message: 'Logradouro inválido',
      }),
    bairro: yup
      .string()
      .required('Bairro é obrigatório')
      .matches(/^[A-Za-z0-9\u00C0-\u00FF \-()]+$/, {
        message: 'Bairro inválido',
      }),
    cidade: yup
      .string()
      .required('Cidade é obrigatório')
      .matches(/^[A-Za-z\u00C0-\u00FF \-()]+$/, {
        message: 'Cidade inválida',
      }),
    estado: yup
      .string()
      .required('Estado é obrigatório')
      .matches(/^[A-Z]{2}$/, {
        message: 'Estado inválido',
      }),
    complemento: yup.string().matches(/^[a-zA-ZÀ-ÿ0-9 ]+$/, {
      message: 'Complemento contém caracter inválido',
    }),
    nacionalidade: yup.string().required('Nacionalidade é obrigatório'),
    estadoNacionalidade: yup.string().when('nacionalidade', {
      is: JSON.stringify({
        Id: 1,
        Nacionalidade: 'Brasileira',
        Sigla3Caracteres: 'BRA',
      }),
      then: (schema) => schema.required('Naturalidade Estado é obrigatório'),
      otherwise: (schema) => schema.notRequired(),
    }),
    idNaturalidade: yup.string().when('nacionalidade', {
      is: JSON.stringify({
        Id: 1,
        Nacionalidade: 'Brasileira',
        Sigla3Caracteres: 'BRA',
      }),
      then: (schema) => schema.required('Naturalidade Município é obrigatório'),
      otherwise: (schema) => schema.notRequired(),
    }),
    pessoaPoliticamenteExposta: yup.boolean(),
    orgaoPPE: yup.string().when('pessoaPoliticamenteExposta', {
      is: true,
      then: (schema) =>
        schema
          .required('Órgão é obrigatório')
          .matches(/^[A-Za-z0-9\u00C0-\u00FF ]+$/, {
            message: 'Órgão inválido',
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    cargoPPE: yup.string().when('pessoaPoliticamenteExposta', {
      is: true,
      then: (schema) =>
        schema.matches(/^[A-Za-z0-9\u00C0-\u00FF ]+$/, {
          message: 'Cargo inválido',
        }),
      otherwise: (schema) => schema.notRequired(),
    }),
    dataNomeacao: yup
      .date()
      .nullable()
      .max(new Date(), 'Data de nomeação não pode ser maior que a data atual')
      .when('pessoaPoliticamenteExposta', {
        is: true,
        then: (schema) =>
          schema
            .required('Data de nomeação é obrigatório')
            .test(
              'dataNomeacao',
              'Data de nomeação deve ser maior ou igual a 18 anos após a data de nascimento',
              function (value) {
                const { dataNascimento } = this.parent;
                const dataMinima = new Date(dataNascimento);
                dataMinima.setFullYear(dataMinima.getFullYear() + 18);
                return value >= dataMinima;
              }
            ),
        otherwise: (schema) => schema.notRequired(),
      })
      .typeError('Data de nomeação inválida'),
    dataExoneracao: yup
      .date()
      .nullable()
      .min(
        yup.ref('dataNomeacao'),
        'Data de exoneração menor que data de nomeação'
      )
      .max(new Date(), 'Data de exoneração não pode ser maior que a data atual')
      .typeError('Data de exoneração inválida'),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
      dataNascimento: null,
      sexo: '',
      cpf: '',
      celular: '',
      email: '',
      matriculaPatrocinadora: '',
      dataAdmissao: null,
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      nacionalidade: '',
      estadoNacionalidade: '',
      idNaturalidade: '',
      pessoaPoliticamenteExposta: false,
      orgaoPPE: '',
      cargoPPE: '',
      dataNomeacao: null,
      dataExoneracao: null,
      termos: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!values.termos) {
        return toast.error('Aceite os termos para continuar');
      }
      values.nacionalidade = JSON.parse(values.nacionalidade);
      const dados = JSON.parse(sessionStorage.getItem('@user:dados'));
      sessionStorage.setItem(
        '@user:dados',
        JSON.stringify({ ...dados, ...values })
      );
      await handleNext();
    },
  });

  /* const getNaturalidade = function (estadoNacionalidade) {
    const naturalidade = estadoLista.filter(
      (estado) => estado.sigla == estadoNacionalidade
    );
    return naturalidade[0]?.nome ?? 'Estrangeiro';
  }; */

  const handleLoadFormulario = async () => {
    const loading = toast.loading('Carregando formulário');
    setIsLoadingFormulario(true);

    try {
      await handleLoadNacionalidadeLista();
      const getDados = JSON.parse(sessionStorage.getItem('@user:dados'));

      formik.setFieldValue('nome', formatarNome(getDados.nome));
      formik.setFieldValue(
        'dataNascimento',
        getDados.dataNascimento ? new Date(getDados.dataNascimento) : null
      );
      formik.setFieldValue('sexo', getDados.sexo);
      formik.setFieldValue('cpf', formataCpf(getDados.cpf));
      formik.setFieldValue('celular', formataTelefone(getDados.celular));
      formik.setFieldValue('email', getDados.email);
      formik.setFieldValue(
        'matriculaPatrocinadora',
        getDados.matriculaPatrocinadora
      );
      formik.setFieldValue(
        'dataAdmissao',
        getDados.dataAdmissao ? new Date(getDados.dataAdmissao) : null
      );
      formik.setFieldValue('cep', getDados.cep);
      formik.setFieldValue('logradouro', getDados.logradouro);
      formik.setFieldValue('numero', getDados.numero);
      formik.setFieldValue('complemento', getDados.complemento ?? '');
      formik.setFieldValue('bairro', getDados.bairro);
      formik.setFieldValue('cidade', getDados.cidade);
      formik.setFieldValue('estado', getDados.estado);

      if (getDados.nacionalidade.Sigla3Caracteres) {
        handleLoadEstadoNacionalidadeLista(
          getDados.nacionalidade.Sigla3Caracteres
        );
        handleLoadCidadeNacionalidadeLista(
          getDados.nacionalidade.Sigla3Caracteres,
          getDados.estadoNacionalidade
        );
        formik.setFieldValue(
          'nacionalidade',
          JSON.stringify(getDados.nacionalidade)
        );
        formik.setFieldValue(
          'estadoNacionalidade',
          getDados.estadoNacionalidade
        );
        formik.setFieldValue('idNaturalidade', getDados.idNaturalidade);
      }

      formik.setFieldValue(
        'pessoaPoliticamenteExposta',
        getDados.pessoaPoliticamenteExposta
      );
      formik.setFieldValue('orgaoPPE', getDados.orgaoPPE);
      formik.setFieldValue('cargoPPE', getDados.cargoPPE);
      formik.setFieldValue(
        'dataNomeacao',
        getDados.dataNomeacao ? new Date(getDados.dataNomeacao) : null
      );
      formik.setFieldValue(
        'dataExoneracao',
        getDados.dataExoneracao ? new Date(getDados.dataExoneracao) : null
      );

      setTravarCadastro(getDados.travarCadastro);

      if (getDados.travarCadastro === 2) {
        formik.setFieldValue('termos', true);
        /* if (getDados.status === 875) {
          setActiveStep(3);
        } else {
          setActiveStep(2);
        } */
      }

      setDadosApi(getDados);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingFormulario(false);
      toast.dismiss(loading);
    }
  };

  const handleCep = async (cep) => {
    setLoadingCep(true);
    try {
      setEnderecoDesabilitado(false);
      const result = await buscarPorCEP(cep);
      formik.setFieldValue('logradouro', result.logradouro);
      formik.setFieldValue('bairro', result.bairro.substr(0, 30));
      formik.setFieldValue('cidade', result.localidade);
      formik.setFieldValue('estado', result.uf);
      setEnderecoDesabilitado(true);
    } catch (error) {
      if (error.isAxiosError) {
        toast.error(error.response.data.msgs[0]);
      } else {
        toast.error('Não foi possível consultar o CEP');
      }
    }
    setLoadingCep(false);
  };

  const isDisabledCpf = () => {
    if ((Boolean(dadosApi) && !dadosApi.cpf) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledEmail = () => {
    if ((Boolean(dadosApi) && !dadosApi.email) || isLoadingFormulario) {
      return false;
    }
    return true;
  };
  const isDisabledSexo = () => {
    if ((Boolean(dadosApi) && !dadosApi.sexo) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledMatricula = () => {
    if ((Boolean(dadosApi) && !dadosApi.numMatricula) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledDtAdmisao = () => {
    if ((Boolean(dadosApi) && !dadosApi.dtAdmissao) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledDtNasc = () => {
    if ((Boolean(dadosApi) && !dadosApi.dtNascimento) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledAll = () => {
    if (travarCadastro == 2 || isLoadingFormulario) {
      return true;
    }
    return false;
  };

  const isDisabledForm = () => {
    if (travarCadastro == 1 || isLoadingFormulario) {
      return true;
    }
    return false;
  };

  const naoTrava = () => {
    if (travarCadastro == 0 || travarCadastro == null) {
      return false;
    }
    return true;
  };

  const handleLoadNacionalidadeLista = async () => {
    const result = await getNacionalidades();
    setNacionalidadeLista(result);
  };

  const handleLoadEstadoNacionalidadeLista = async (codPais) => {
    const result = await getEstados(codPais);
    setEstadoLista(result);
  };

  const handleLoadCidadeNacionalidadeLista = async (codPais, codUf) => {
    const result = await getCidadesNacionalidade(codPais, codUf);
    setCidadeLista(result);
  };

  return {
    formik,
    handleLoadFormulario,
    disabled_cpf: isDisabledCpf(),
    disabled_sexo: isDisabledSexo(),
    disabled_matricula: isDisabledMatricula(),

    disabled_email: isDisabledEmail(),
    disabled_dtAdmissao: isDisabledDtAdmisao(),
    disabled_dtnasc: isDisabledDtNasc(),
    disabledAll: isDisabledAll(),
    disabledForm: isDisabledForm(),
    naoDesabilita: naoTrava(),

    handleCep,
    nacionalidadeLista,
    estadoLista,
    cidadeLista,
    loadingCep,
    isLoadingFormulario,
    // loadingAvancar,
    handleLoadEstadoNacionalidadeLista,
    handleLoadCidadeNacionalidadeLista,
    enderecoDesabilitado,
  };
}
