import { Dialog } from '@mui/material';

import { Button } from 'components/Button';

import { useModal } from 'context/Modal';

import { ModalWrapper, TextContainer, Footer } from './styles';

export function ModalAvisoFinal() {
  const { showModalAvisoFinal, handleCloseModalAvisoFinal } = useModal();

  return (
    <Dialog
      onClose={handleCloseModalAvisoFinal}
      aria-labelledby="simple-dialog-title"
      open={showModalAvisoFinal}
      scroll="body"
      fullWidth
    >
      <ModalWrapper>
        <TextContainer>
          <h2>Atenção!</h2>
          <p>
            A qualquer momento a atualização cadastral poderá ser feita pelo
            Portal Petros.
          </p>
        </TextContainer>

        <Footer>
          <Button
            style={{ width: '159px !important' }}
            normal
            onClick={() => {
              handleCloseModalAvisoFinal();
            }}
          >
            Entendido
          </Button>
        </Footer>
      </ModalWrapper>
    </Dialog>
  );
}
