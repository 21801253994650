import { useEffect, useState } from 'react';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';

import { usePersonalInfoForm } from 'hook/usePersonalInfoForm';
import { useResponsive } from 'hook/useResponsive';
import { postAcb } from 'services/acbServices';
import { consultarBpm } from 'services/usuariosServices';
import formataCpf from 'util/formatarCPF';

import { Button } from 'components/Button';
import { ModalAvisoFinal } from 'components/ModalAvisoFinal';
import { ModalProcessandoInscricao } from 'components/ModalProcessandoInscricao';

import { useAuth } from 'context/Auth';
import { useContribuicao } from 'context/Contribuicao';
import { useInfoPessoais } from 'context/InfoPessoais';
import { useModal } from 'context/Modal';
import { useSteps } from 'context/Steps';
import { useToast } from 'context/Toast';
// import { useTributacao } from 'context/Tributacao';

import contribuicaoIcon from 'assets/images/contribuicao.svg';
import vinculadoIcon from 'assets/images/person_group.svg';
import personIcon from 'assets/images/person.svg';

// import tributacaoIcon from 'assets/images/tributacao.svg';
import { ConcluirInscricaoMobile } from './mobile';
import {
  Card,
  CardContainer,
  CardItem,
  Container,
  ContainerButton,
  ContainerDeclaracao,
  ContainerInformacoes,
  ContainerInformacoesText,
  EditarButton,
  Image,
  TitleContainer,
} from './styles';

export function ConcluirInscricao() {
  const { mobileLayout } = useResponsive();
  const { toast } = useToast();
  const { salario, porcentagem1, porcentagem2 } = useContribuicao();
  const { selectStep } = useSteps();
  const { formik, handleLoadFormulario, isLoadingFormulario } =
    usePersonalInfoForm();
  const {
    showModalAvisoFinal,
    setShowModalAvisoFinal,
    openModalAvisoFinal,
    openModalProcessandoInscricao,
    handleCloseModalProcessandoInscricao,
  } = useModal();
  // const { handleLoadTributacao } = useTributacao();
  const { setPage } = useInfoPessoais();
  const { nome, cpf } = JSON.parse(sessionStorage.getItem('@user:dados'));
  const [checkedDeclaracao, setCheckedDeclaracao] = useState(false);
  // const [vinculados, setVinculados] = useState([]);
  const [loading, setLoading] = useState(false);
  const { travarCadastro, setTravarCadastro } = useAuth();

  const handleChecked = () => {
    setCheckedDeclaracao(!checkedDeclaracao);
  };

  const verificarSeTudoPreenchido = async () => {
    const dados = JSON.parse(sessionStorage.getItem('@user:dados'));

    if (!formik.values.nome) {
      return toast.error('Nome não preenchido');
    }
    if (!formik.values.dataNascimento) {
      return toast.error('Data de nascimento não preenchida');
    }
    if (!formik.values.sexo) {
      return toast.error('Sexo não preenchido');
    }
    if (!formik.values.cpf) {
      return toast.error('CPF não preenchido');
    }
    if (!formik.values.celular) {
      return toast.error('Celular não preenchido', { id: 'toast' });
    }
    if (!formik.values.email) {
      return toast.error('E-mail não preenchido', { id: 'toast' });
    }
    if (!formik.values.matriculaPatrocinadora) {
      return toast.error('Matricula da patrocinadora não preenchido', {
        id: 'toast',
      });
    }
    if (!formik.values.dataAdmissao) {
      return toast.error('Data Admissão não preenchido', { id: 'toast' });
    }
    if (!formik.values.cep) {
      return toast.error('CEP não preenchido', { id: 'toast' });
    }
    if (!formik.values.logradouro) {
      return toast.error('Logradouro não preenchido', { id: 'toast' });
    }
    if (!formik.values.bairro) {
      return toast.error('Bairro não preenchido', { id: 'toast' });
    }
    if (!formik.values.cidade) {
      return toast.error('Cidade não preenchido', { id: 'toast' });
    }
    if (!formik.values.estado) {
      return toast.error('Estado não preenchido', { id: 'toast' });
    }
    if (!formik.values.nacionalidade) {
      return toast.error('Nacionalidade não preenchido', { id: 'toast' });
    }
    if (!formik.values.estadoNacionalidade) {
      return toast.error('Nacionalidade não preenchido', { id: 'toast' });
    }
    if (!formik.values.idNaturalidade) {
      return toast.error('Naturalidade não preenchido', { id: 'toast' });
    }
    if (formik.values.pessoaPoliticamenteExposta == true) {
      if (!formik.values.orgaoPPE) {
        return toast.error('Orgão PEP não preenchido', { id: 'toast' });
      }
      /* if (!formik.values.cargoPPE) {
        return toast.error('Cargo PEP não preenchida', { id: 'toast' });
      } */
      if (!formik.values.dataNomeacao) {
        return toast.error('Data de nomeação não preenchido', { id: 'toast' });
      }
    }
    if (!salario) {
      return toast.error('Salário não preenchido');
    }
    if (!porcentagem1) {
      return toast.error('Contribuição básica não preenchido');
    }

    if (!checkedDeclaracao) {
      return toast.error('Você precisa aceitar as informações', {
        id: 'declaracao',
      });
    }

    const loading = toast.loading('Enviando dados...', { id: 'toast' });
    try {
      setLoading(true);
      await enviarPedido(dados);
    } catch (error) {
      openModalProcessandoInscricao();
      const interval = setInterval(async () => {
        try {
          setLoading(true);
          await consultarBpm(dados.cpf);
          await enviarPedido(dados);
          handleCloseModalProcessandoInscricao();
          setLoading(false);
          clearInterval(interval);
        } catch (e) {
          // nada
        }
      }, 10000);
      // toast.error(error.response?.data.message);
    } finally {
      toast.dismiss(loading);
      setLoading(false);
    }
  };

  const enviarPedido = async (dados) => {
    await postAcb(dados);
    toast.success('Dados enviados com sucesso', { id: 'toast' });
    openModalAvisoFinal();
    dados.status = 871;
    dados.travarCadastro = 2;
    sessionStorage.setItem('@user:dados', JSON.stringify(dados));
    setTravarCadastro(2);
  };

  useEffect(() => {
    // handleLoadVinculados();
    handleLoadFormulario();
  }, []);

  return (
    <>
      {!mobileLayout && (
        <>
          <Container>
            <TitleContainer>
              <h2>Confira os dados abaixo antes de concluir sua inscrição</h2>
            </TitleContainer>
            <CardContainer>
              <Card>
                <CardItem>
                  <Image src={personIcon} alt="Pessoa" />

                  <ContainerInformacoes>
                    <h3>Informações pessoais</h3>
                    <ContainerInformacoesText>
                      {nome}
                      <br />
                      {formataCpf(cpf)}
                    </ContainerInformacoesText>

                    <EditarButton
                      onClick={() => {
                        setPage('formulario');
                        selectStep(0);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem>
                <CardItem>
                  <Image src={contribuicaoIcon} alt="Contribuição" />

                  <ContainerInformacoes>
                    <h3>Contribuição</h3>
                    <ContainerInformacoesText>
                      Básica: {porcentagem1}%
                      <br />
                      Voluntária: {porcentagem2}%
                    </ContainerInformacoesText>

                    <EditarButton
                      onClick={() => {
                        setPage('contribuicao');
                        selectStep(1);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem>
              </Card>
              <Card>
                <CardItem>
                  <Image src={vinculadoIcon} alt="Vinculados" />

                  <ContainerInformacoes>
                    <h3>Vinculados</h3>
                    <span style={{ color: '#4f4f4f' }}>
                      Após o deferimento do pedido de inscrição você poderá
                      realizar a inclusão dos vinculados através do Portal
                      Petros, acessando com matrícula e senha
                    </span>
                    {/* <ContainerInformacoesText>
                      {vinculados.map((vinculado) => (
                        <p> {vinculado.nome} </p>
                      ))}
                    </ContainerInformacoesText> */}
                  </ContainerInformacoes>
                </CardItem>
                {/* <CardItem>
                  <Image src={tributacaoIcon} alt="Tributação" />

                  <ContainerInformacoes>
                    <h3>Tributação</h3>
                    <ContainerInformacoesRegimeText style={{ width: '90px' }}>
                      {verificarRegime()}
                    </ContainerInformacoesRegimeText>

                    <EditarButton
                      onClick={() => {
                        selectStep(3);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem> */}
              </Card>
            </CardContainer>
            <ContainerDeclaracao>
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                checked={checkedDeclaracao}
                onChange={() => {
                  handleChecked();
                }}
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <div>
                Declaro, para os devidos fins, que as informações prestadas
                acima são verdadeiras e assumo inteira responsabilidade pelas
                mesmas.
              </div>
            </ContainerDeclaracao>

            <ContainerButton>
              <Button
                style={{ color: '#fff' }}
                normal
                onClick={() => {
                  // Validação de todos os dados
                  verificarSeTudoPreenchido();
                }}
                disabled={
                  loading || isLoadingFormulario || travarCadastro === 2
                }
              >
                Enviar pedido de inscrição
              </Button>
            </ContainerButton>
          </Container>

          <ModalAvisoFinal
            showModal={showModalAvisoFinal}
            setShowModal={setShowModalAvisoFinal}
          />

          <ModalProcessandoInscricao />
        </>
      )}

      {mobileLayout && <ConcluirInscricaoMobile />}
    </>
  );
}
