import { api } from './api';

export async function getUsers() {
  const { data } = await api.get(`/ciencia-patrocinadora/users`);
  return data;
}

export async function getUserByCpf(cpf) {
  const { data } = await api.get(`/users/findbycpf/${cpf}`);
  return data;
}

export async function getOneUser(numPedido, numIdPf) {
  const { data } = await api.get(`/users/findbyid/${numPedido}/${numIdPf}`);
  return data;
}

export async function putIndeferir(numPedido) {
  const { data } = await api.put(
    `/ciencia-patrocinadora/indeferimento/${numPedido}`
  );
  return data;
}

export async function putDeferir(numPedido) {
  const { data } = await api.put(
    `/ciencia-patrocinadora/deferimento/${numPedido}`
  );
  return data;
}

export async function buscarPorCEP(cep) {
  const { data } = await api.get(`users/consulta-cep/${cep}`);
  return data;
}

export async function alterarEmail(cpf, email) {
  const { data } = await api.put(`users/${cpf}/email`, { email });
  return data;
}

export async function updateUser(numPedido, user) {
  const { data } = await api.put(
    `/ciencia-patrocinadora/user/${numPedido}`,
    user
  );
  return data;
}

export async function obterStatusDoPedido(numPedido) {
  const { data } = await api.get(`users/${numPedido}/status`);
  return data;
}

export async function consultarBpm(cpf) {
  const { data } = await api.get(`users/status?cpf=${cpf}`);
  return data;
}
