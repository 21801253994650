export const steps = [
  {
    index: 0,
    numero: 1,
    page: 'formulario',
    text: 'Informações pessoais',
  },
  {
    index: 1,
    numero: 2,
    text: 'Contribuição',
  },
  {
    index: 2,
    numero: 3,
    text: 'Status e confirmação',
  },
];
