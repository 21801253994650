import { useState } from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import PropTypes from 'prop-types';
import formatarCPF from 'util/formatarCPF';
// import { mascaraMoedaGrid } from 'util/formatReal';

// import { useModal } from 'context/Modal';
import { useModalProspect } from 'context/ModalProspect';
import { useProspect } from 'context/Prospects';

import { ModalAvisoDeletar } from '../ModalAviso';
import { FixedTh, StyledTableHead } from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'cpf', label: 'CPF' },
  { id: 'nome', label: 'Nome do Prospect' },
  { id: 'numMatricula', label: 'Matrícula' },
  { id: 'dtAdmissao', label: 'Data de Admissão' },
  { id: 'dtNasc', label: 'Data de Nascimento' },
  { id: 'valSalario', label: 'Salário' },
  { id: 'sexo', label: 'Sexo' },
  { id: 'actions', label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <FixedTh
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </FixedTh>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TabelaProspect(props) {
  const {
    openModalCadastro,
    showModalDeletar,
    setShowModalDeletar,
    openModalDeletar,
  } = useModalProspect();
  const { handleEdit } = useProspect();
  const { rows } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const saveLocalStorage = (row) => {
    localStorage.setItem('@petros:prospect', JSON.stringify(row));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function formatarRealComCentavos(valor) {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {formatarCPF(row.cpf)}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.nome}
                      </TableCell>

                      <TableCell align="center" component="th" scope="row">
                        {row.matriculaPatrocinadora}
                      </TableCell>

                      <TableCell align="center">
                        {new Date(row.dataAdmissao).toLocaleDateString('pt-BR')}
                      </TableCell>

                      <TableCell align="center">
                        {new Date(row.dataNascimento).toLocaleDateString(
                          'pt-BR'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {formatarRealComCentavos(row.salario ?? '')}
                      </TableCell>

                      <TableCell align="center">
                        {row.sexo === 1 ? 'Masculino' : ' Feminino'}
                      </TableCell>

                      <TableCell align="center">
                        <>
                          <IconButton
                            aria-label="edit"
                            className="m-2"
                            size="medium"
                            onClick={() => {
                              openModalCadastro();
                              handleEdit(row);
                            }}
                            disabled={!row.canEdit}
                          >
                            <AiFillEdit fontSize="inherit" />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            className="m-2"
                            size="medium"
                            onClick={() => {
                              openModalDeletar();
                              saveLocalStorage(row);
                            }}
                            disabled={!row.canEdit}
                          >
                            <AiFillDelete fontSize="inherit" />
                          </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 3 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { label: 'Tudo', value: -1 }]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `Mostrando ${from}-${to} total de ${count} registros`
            }
            labelRowsPerPage="Registros por página"
            SelectProps={{
              inputProps: { 'aria-label': 'Linhas por página' },
              native: true,
            }}
          />
        </Paper>
      </Box>

      <ModalAvisoDeletar
        showModal={showModalDeletar}
        setShowModal={setShowModalDeletar}
      />
    </>
  );
}
