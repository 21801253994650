import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const StyledButton = styled(LoadingButton)(() => ({
    backgroundColor: '#212429',
    '&:hover': {
        backgroundColor: '#17847B',
    },
}));

export default function ColorButton({ children, ...props }) {
    return <StyledButton {...props}>{children}</StyledButton>;
}